const firebaseConfig = {
  projectId: "koningsberg-32409",
  appId: "1:882420487415:web:007a11eaf4c29b81caaff2",
  databaseURL:
    "https://koningsberg-32409-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "koningsberg-32409.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyD5wswhYaWuvCsdZNJUOsp3P6yR4bRoj0M",
  authDomain: "koningsberg-32409.firebaseapp.com",
  messagingSenderId: "882420487415",
  measurementId: "G-YE951DY6NN",
};

export default firebaseConfig;
